import { TIMEFRAME_OPTIONS } from '../../../lib/constants';
import { useFormContext } from 'react-hook-form';
import { useFormDataContext } from '../../../lib/contexts';
import { useRef } from 'react';
import CustomBackButton from '../../CustomComponents/CustomBackButton';
import { useScrollToTop } from '../../../lib/hooks';

function FormStep4() {
  const { register, setValue, trigger, handleSubmit, formState } =
    useFormContext();
  const { updateFormData, formData, updateStep } = useFormDataContext();
  const { errors } = formState;
  const timeframeRef = useRef();

  const handleClick = (event) => {
    event.stopPropagation();
    if (event.target.type !== 'radio') {
      const input = event.currentTarget.querySelector('input[type="radio"]');
      input.click();
    }
  };

  const handleClickContinue = handleSubmit(async (_data) => {
    const isValid = await trigger(['timeframe']);

    if (!isValid) {
      const [firstErrorKey] = Object.keys(errors);
      const errorRefs = {
        timeframe: timeframeRef,
      };

      errorRefs[firstErrorKey]?.current?.focus();
    } else {
      // Assuming updateStep increments the step by 1
      updateStep(formData.step + 1); // Update step on radio select
    }
  });
  const handleRadioChange = async (e) => {
    await setValue('timeframe', e.target.value);
    updateFormData({ timeframe: e.target.value });
    handleClickContinue();
  };

  useScrollToTop();

  return (
    <>
      <div id="question4">
        <h3 className="step-title">How Soon Do You Want Your Money?</h3>
        <ul className="radio-list">
          {TIMEFRAME_OPTIONS.map(
            ({ id, value, textOption, registerLabel, subText }, index) => (
              <li
                key={`${id}-${index}`}
                className={`radio-list-item ${
                  errors[registerLabel] && 'form-step__error'
                }`}
                onClick={handleClick}
              >
                <input
                  type="radio"
                  value={value}
                  checked={formData.timeframe === value}
                  id={id}
                  ref={timeframeRef}
                  {...register(registerLabel, { required: true })}
                  onClick={handleRadioChange}
                />
                <label className="radio-button">
                  <span htmlFor={id}>
                    {textOption}
                    <span className='how-soon-subtext'>{subText ?? subText}</span>
                  </span>
                  <div className="radiobutton"></div>
                </label>
              </li>
            )
          )}
        </ul>
        <CustomBackButton/>
      </div>
    </>
  );
}
export default FormStep4;
