import {
  AddressProvider,
  AppProvider,
  FormDataProvider,
  LocationProvider,
  ZipProvider,
  PrePopProvider,
  DisclaimerProvider,
  LenderProvider
} from './';

import { HelmetProvider } from 'react-helmet-async';

const CombinedProviders = ({ children }) => (
  <HelmetProvider>
    <PrePopProvider>
      <AddressProvider>
        <AppProvider>
          <DisclaimerProvider>
            <LenderProvider>
              <ZipProvider>
                <LocationProvider>
                  <FormDataProvider>{children}</FormDataProvider>
                </LocationProvider>
              </ZipProvider>
            </LenderProvider>
          </DisclaimerProvider> 
        </AppProvider>
      </AddressProvider>
    </PrePopProvider>
  </HelmetProvider>
);

export default CombinedProviders;
