import { FormProvider, useForm } from 'react-hook-form';
import {
  FormStep1,
  FormStep10,
  FormStep11,
  FormStep3,
  FormStep4,
  FormStep6,
} from './steps';
import { getErrorMessage, submitForm, submitPostMatchForm, formatPhoneNumber } from '../../lib/utils';
import { useAppContext, useFormDataContext, usePrePopContext, useLendersContext } from '../../lib/contexts';
import { CustomLoader } from '../CustomComponents';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';
import {useEffect, useState} from 'react';
import { DesignCheckbox, DesignYesNo } from './steps/OneToOne';
import DesignOneToOne from './steps/OneToOne/DesignOneToOne';
import DesignNoOneToOne from './steps/OneToOne/DesignNoOneToOne';
import CustomLoaderLightBox from '../CustomComponents/CustomLoaderLightBox/CustomLoaderLightBox';

function Form({isLastStep, setIsLastStep}) {
  const { setLendersContext } = useLendersContext();
  const { appContext } = useAppContext();
  const { formData, clearStorage, updateFormData, updateStep } = useFormDataContext({});
  const methods = useForm({ defaultValues: formData, mode: 'onSubmit' });
  const { step } = formData;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNoText, setIsLoadingNoText] = useState(false);
  const { formState } = methods;
  const { errors } = formState;
  const className = `stepBar step${step}`;

  const [firstOneToOneSubmit, setFirstOneToOneSubmit] = useState(true);

  const [phone, setPhone] = useState(null)
  const [leadId, setLeadId] = useState(null);
  const [lenderList, setLenderList] = useState([]);
  const [selectedLenders, setSelectedLenders] = useState([]);
  const [noOneToOneDisclaimer, setNoOneToOneDisclaimer] = useState(false);

  const navigate = useNavigate();

  const handleSelect = (e) => {
    const { checked, value } = e.target;
    setSelectedLenders(
      checked
        ? [...selectedLenders, value]
        : selectedLenders.filter((item) => item !== value)
    );
  };

  const selectAll = (list) => {
    setSelectedLenders(list);
  }

  const submitPostMatch = async () => {
    // setIsLoading(true);
    setIsLoadingNoText(true);
    // setNoOneToOneDisclaimer(false);

    let tcpa;

    const tcpaLabel = document.querySelector(
      'label[for="leadid_tcpa_disclosure"]'
    );

    if (tcpaLabel) {
      tcpa = tcpaLabel.textContent;
    } else {
      const tcpaLabelTop = document.querySelector(
        'label[for="leadid_tcpa_disclosure_t"]'
      );

      const tcpaLabelBottom = document.querySelector(
        'label[for="leadid_tcpa_disclosure_b"]'
      );

      if (tcpaLabelTop && tcpaLabelBottom) {
        tcpa = `${tcpaLabelTop.textContent} ${tcpaLabelBottom.textContent}`;
      }
    }

    const result = await submitPostMatchForm({ lead: leadId, brand_consent: selectedLenders, tcpa });

    const lendersContextData = {
      name: `${formData.firstname} ${formData.lastname}`,
      zipCode: formData.zip,
    };

    if (result.oneToOne && result.oneToOne.length) {
      lendersContextData.lenders = result.lenders;

      setLenderList(result.oneToOne);
      updateStep(7);
    }

    if (result.pixel) {
      lendersContextData.pixel = result.pixel;
    }

    setLendersContext(lendersContextData);

    setFirstOneToOneSubmit(false);
    setNoOneToOneDisclaimer(false);

    if (result.status === 'matched') {
      clearStorage();
      // navigate('/thank-you/lender-list');
      navigate('/thank-you',{ state: {pixel:result.pixel} });
    }else if (
      !result.oneToOne ||
      !result.oneToOne.length
    ) {
      clearStorage();
      navigate('/thank-you',{ state: {pixel:result.pixel} });
      // navigate('/thank-you/alternative');
    }
    setIsLoading(false);
    setIsLoadingNoText(false);
  }

  const handleGlobalSubmit = async (data) => {
    const combinedData = { ...data, ...formData, ...appContext };

    setIsLoading(true);

    await new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });

    Object.keys(data).forEach((key) => {
      if (formData[key] === '' && data[key] !== '') {
        combinedData[key] = data[key];
      }
    });

    setPhone(formatPhoneNumber(data.phone));
    const submitResult = await submitForm(combinedData);

    const lendersContextData = {
      name: `${formData.firstname} ${formData.lastname}`,
      zipCode: formData.zip,
    };

    if (submitResult.lenders && submitResult.lenders.length) {
      lendersContextData.lenders = submitResult.lenders;
    }

    if (submitResult.noOneToOneDisclaimer) {
      setNoOneToOneDisclaimer(true);
      setLeadId(submitResult.lead_id);
      updateStep(7);
      setIsLoading(false);
    }

    if (submitResult.oneToOne && submitResult.oneToOne.length) {
      lendersContextData.lenders = submitResult.lenders;
      setLeadId(submitResult.lead_id);
      setLenderList(submitResult.oneToOne);
      updateStep(7);
    }

    // let pixel = null;

    if (submitResult.pixel) {
      lendersContextData.pixel = submitResult.pixel;
    }

    setLendersContext(lendersContextData);

    if (submitResult.status === 'matched') {
      clearStorage();
      // navigate('/thank-you/lender-list');
      navigate('/thank-you');
    }else if (
      // !submitResult.oneToOne ||
      // !submitResult.oneToOne.length

      (!submitResult.oneToOne || !submitResult.oneToOne.length) &&
      !submitResult.noOneToOneDisclaimer
    ) {
      clearStorage();
      // navigate('/thank-you/alternative');
      navigate('/thank-you');
    }

    setIsLoading(false);
    setIsLastStep(true)

    // await navigate('/thank-you',{ state: {pixel:pixel} });

    // clearStorage();
  };

  useEffect(() => {
    if (appContext.prepop) {
      updateFormData({
        firstname: appContext.prepop.firstname,
        lastname: appContext.prepop.lastname,
        email: appContext.prepop.email,
        phone: appContext.prepop.phone,
      });
    }
  }, [appContext]);

  return (
    <>
      {isLoading && !isLoadingNoText? (
        <CustomLoader />
      ) : (
        <div className={`step-form-wrap ${isLastStep && 'form-no-border'}`}>
          {isLoadingNoText && (
            <CustomLoaderLightBox />
      )}
          <h3 className="text-center" style={{ display: step === 7 ? 'none' : 'block' }}>Embark on a Bright Financial Future Today.</h3>
          <div className={className}>
            <div className="steps col-12">
              <span>Step &nbsp;</span>
              <span className="current">{step} </span> &nbsp;/ 12
            </div>
          </div>
          <FormProvider {...methods}>
            <form
              id="leadform"
              className="step-form"
              name="leadform"
              onSubmit={methods.handleSubmit(handleGlobalSubmit)}
            >
              <div className="step active" data-step={step}></div>
              <div className="inner">
                {!isEmpty(errors) && (
                  <div className="errors">{getErrorMessage(errors)}</div>
                )}
                {step === 1 && <FormStep1 />}
                {step === 2 && <FormStep3 />}
                {step === 3 && <FormStep4 />}
                {step === 4 && <FormStep6 />}
                {step === 5 && <FormStep10 />}
                {step === 6 && <FormStep11 />}
                {step === 7 && (
                  <>
                    {lenderList && lenderList.length > 0 && (
                      <DesignCheckbox
                        lenderList={lenderList}
                        onSelect={handleSelect}
                        selectedList={selectedLenders}
                        onSubmit={submitPostMatch}
                        phone={phone}
                        firstOneToOneSubmit={firstOneToOneSubmit}
                        selectAll={selectAll}
                        setIsLoading={setIsLoading}
                        setIsLoadingNoText={setIsLoadingNoText}
                      />
                    )}
                    {noOneToOneDisclaimer && (
                      <DesignNoOneToOne onSubmit={submitPostMatch} />
                    )}
                  </>
                )}
              </div>
            </form>
          </FormProvider>
        </div>
      )}
    </>
  );
}

export default Form;
