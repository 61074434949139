import debounce from 'lodash/debounce';

export const handleKeyDown = (e) => {
  if (e.keyCode === 13) {
    e.preventDefault();
  }
};

export const debounceUpdate = debounce((key, value, onChange) => {
  onChange({ [key]: value });
}, 500);

export const submitForm = async (combinedData) => {
  const trustedformtoken = `${process.env.REACT_APP_TRUSTED_FORM_API}/${window.trustedFormId}`;
  const leadidtoken = window.leadId || '';
  const anura = sessionStorage.getItem('anura_final_response');
  // const tcpa = document.getElementById('leadid_tcpa_disclosure_label').textContent || '';
  const clickid = window.clickId || '';

  delete combinedData.prepop;
  delete combinedData.step;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...combinedData,
      trustedformtoken,
      leadidtoken,
      anura,
      // tcpa,
      clickid,
    }),
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/lead/webprematch`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    const result = await response.json();

    return { error: false, ...result };
  } catch (error) {
    return { error: true, ...error };
  }
};

export const submitPostMatchForm = async (data) => {

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/lead/webonetoone`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    const result = await response.json();

    return { error: false, ...result };
  } catch (error) {
    return { error: true, ...error };
  }
};

export const formatPhoneNumber = (phone) => {
  if (!phone) return '';
  const cleaned = ('' + phone).replace(/\D/g, ''); // Remove all non-digit characters
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phone; // Return the original value if it doesn't match
};