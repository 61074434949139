import { useState } from 'react';
import { useDisclaimerContext } from '../../../../lib/contexts';
import { CustomModalContent } from '../../../CustomComponents';

function DesignNoOneToOne({onSubmit}) {
  const { disclaimerContext } = useDisclaimerContext();
  const { partnerList, termsOfUse, privacyPolicy, arbitrate } = disclaimerContext;

  const [isOpen, setIsOpen] = useState({
    partnerList: false,
    termsOfUse: false,
    privacyPolicy: false,
    arbitrate: false,
  });

  const openModal = (key) => setIsOpen({ ...isOpen, [key]: true });
  const closeModal = (key) => setIsOpen({ ...isOpen, [key]: false });

  const click = (event) => {
    if (event.target?.id === 'ppTag') {
      setIsOpen({
        partnerList: false,
        termsOfUse: false,
        privacyPolicy: true,
        arbitrate: false,
      });
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.target?.id === 'touTag') {
      setIsOpen({
        partnerList: false,
        termsOfUse: true,
        privacyPolicy: false,
        arbitrate: false,
      });
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <div id="question12" style={{ width: '93%' }}>
      <CustomModalContent
        title="Instant Cash Offer Partners"
        isOpen={isOpen.partnerList}
        onClose={() => closeModal('partnerList')}
        content={partnerList.data?.html}
        clickEvent={click}
        isHtml
      />
      <CustomModalContent
        title="Terms Of Use"
        isOpen={isOpen.termsOfUse}
        onClose={() => closeModal('termsOfUse')}
        content={termsOfUse.data?.html}
        clickEvent={click}
        isHtml
      />
      <CustomModalContent
        title="Privacy Policy"
        isOpen={isOpen.privacyPolicy}
        onClose={() => closeModal('privacyPolicy')}
        content={privacyPolicy.data?.html}
        clickEvent={click}
        isHtml
      />
      <CustomModalContent
        title="Arbitrate"
        isOpen={isOpen.arbitrate}
        onClose={() => closeModal('arbitrate')}
        content={arbitrate.data?.html}
        clickEvent={click}
        isHtml
      />
      <form data-tf-element-role="offer">
        <h3 className="step-title step-title-consent">You're Almost There!</h3>
        <div className="form-step">
          <label htmlFor="leadid_tcpa_disclosure">
            <div className='disclaimer-complement'>
              <input type="hidden" id="leadid_tcpa_disclosure" />
              By clicking "<span data-tf-element-role="submit-text">Get My Offer</span>" I am electronically signing and expressly consenting that up to three <a
              className="custom-submit__button-as-link" href="#" onClick={(e) => {
              e.stopPropagation();
              openModal('partnerList');
            }}>Partners</a> may contact me for marketing purposes at the number entered, including with an automated telephone dialing system, pre-recorded or artificial voice, and/or SMS/MMS, and consenting to receive telephone solicitations even if my telephone number is on a state, federal or corporate Do Not Call list; and agreeing to the <a
              href="#"
              className="custom-submit__button-as-link"
              onClick={(e) => {
                e.stopPropagation();
                openModal('privacyPolicy');
              }}
            >
              Privacy Policy
            </a>, <a
              href="#"
              className="custom-submit__button-as-link"
              onClick={(e) => {
                e.stopPropagation();
                openModal('termsOfUse');
              }}
            >
              Terms of Use
            </a>, and to <a
              href="#"
              className="custom-submit__button-as-link"
              onClick={(e) => {
                e.stopPropagation();
                openModal('arbitrate');
              }}
            >
              Arbitrate
            </a> all disputes; and to sharing of my personal data with third-parties. I understand my signature and consent is not a condition of receiving services and I may instead email consent@rgrmarketing.com to receive services, or to revoke my consent.
            </div>
          </label>
        </div>
        <button type="button" onClick={onSubmit} className="custom-submit__btn" data-tf-element-role="submit">
          Get My Offer
        </button>
      </form>
    </div>
  );
}

export default DesignNoOneToOne;
