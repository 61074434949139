import './ThankYou.css';
import React from 'react';
import { Testimonials } from '../MainPage';
import { useLocation } from 'react-router-dom';
import parse from "html-react-parser";
import { useLendersContext } from '../../lib/contexts';

function ThankYou() {
  const {state} = useLocation();
  // const { pixel } = state;
  const { name, pixel } = useLendersContext();
  const parsedPixel = parse(pixel || '');
  return (
    <>
      {pixel && <div>{parsedPixel}</div>}
      <section id="thankYouFirstSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <span>
                Thank you for submitting your request, one of our specialist
                will be in touch with you within 24-48 hours.
              </span>
            </div>
          </div>
        </div>
      </section>
      <Testimonials />
    </>
  );
}

export default ThankYou;
