import './assets/css/bootstrap.min.css';
import './assets/css/accordion.css';
import './assets/css/slick.css';

import {
  Disclaimer,
  Form,
  MainPage,
  PageTitle,
  ThankYou,
  TopBarAndFooter,
} from './components';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { useTrustedForm, useJornaya, useAnura, useExternalScript } from './lib/hooks';
import { Helmet } from 'react-helmet-async';
import { useState } from 'react';

const App = () => {
  const [isLastStep, setIsLastStep] = useState(false)
  useTrustedForm();
  useJornaya();
  useAnura();
  useExternalScript(
      'https://rgr-js.s3.us-west-2.amazonaws.com/tracking.js',
      'tracking_env',
      process.env.REACT_APP_ENV
  );
  useExternalScript(
    '//cdn-4.convertexperiments.com/v1/js/10049462-100413977.js'
  );
  return (
      <div className="page">
        <PageTitle title={process.env.REACT_APP_TAB_NAME}/>
        <Helmet>
        <script type="text/javascript">
          {`var pixelrgr_type = 'homeseller';
                      var pixelrgr_source = '${process.env.REACT_APP_SOURCE}';
                      var pixelrgr_url = "${process.env.REACT_APP_API_URL}";`}
        </script>
        <script src="https://rgr-js.s3.us-west-2.amazonaws.com/pixel.js" type="text/javascript"></script>
        </Helmet>
        <Router>
          <Routes>
            <Route
                path="/"
                element={
                  <>
                    <TopBarAndFooter/>
                    <MainPage/>
                    <div>
                      <div className="disclaimer">
                        <Disclaimer/>
                      </div>
                    </div>
                  </>
                }
            />
            <Route
                path="/form"
                element={
                  <>
                    <TopBarAndFooter
                      isLastStep={isLastStep}
                    />
                    <Form
                      isLastStep={isLastStep}
                      setIsLastStep={setIsLastStep}
                    />
                    <div>
                      <div className="disclaimer">
                        <Disclaimer/>
                      </div>
                    </div>
                  </>
                }
            />
            <Route
                path="/thank-you"
                element={
                  <>
                    <TopBarAndFooter
                      isLastStep={isLastStep}
                    />
                    <ThankYou/>
                    <div>
                      <div className="disclaimer">
                        <Disclaimer/>
                      </div>
                    </div>
                  </>
                }
            />
          </Routes>
        </Router>
      </div>
  );
};

export default App;
