import './customLoaderLightBox.css';

import MoonLoader from 'react-spinners/MoonLoader';
import React from 'react';
import { useScrollToTop } from '../../../lib/hooks';

function CustomLoaderLightBox() {
  useScrollToTop();

  return (
    <div className="loading-state">
      <MoonLoader
        color={'#2cb701'}
        loading={true}
        size={50}
        cssOverride={{
          alignSelf: 'center',
        }}
        aria-label="Loading Spinner"
        data-testid="loader"
        speedMultiplier={0.2}
      />
    </div>
  );
}

export default CustomLoaderLightBox;
